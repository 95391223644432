(function ($) {

    // N.B. cobrandPath and applicationPath are passed in as global variables
    ssm.addState({
        id: "collapsedSpots",
        maxWidth: 905,
        onEnter: function () {
            $(window.collapseSpots);
        },
        onLoad: function () {
            $(window.collapseSpots);
        }
    })




    $.ui.autocomplete.prototype._renderItem = function (ul, item) {
        return $('<li></li>')
            .data("item.autocomplete", item)
            .append('<a><span class="matched">' + item.label.substr(0, this.term.length) + '</span>' + item.label.substr(this.term.length) + '</a>')
            .appendTo(ul);
    };

    // use timer to debounce resize events
    var resizeTimer;
    $(window).on("resize orientationchange", function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(resizeColorbox, 10);
    });

    window.resizeColorbox = function () {
        // dynamically resize colorbox if it's shown
        if ($.colorbox.element().is("a.open-overlay"))
            $.colorbox.resize({
                width: $.colorbox.element().prop("overlay_width"),
                height: $.colorbox.element().prop("overlay_height")
            });

        // reposition example div if visible
        if ($("#example").is(":visible"))
            $("#example").position({ my: "left top", at: "left bottom", of: "#keywords", collision: "none" });

        // reposition autocomplete if visible
        if ($(".ui-autocomplete").is(":visible"))
            $(".ui-autocomplete").position({
                my: "left top", at: "left bottom", of: "#keywords", collision: "none"
            });
    };

    window.collapseSpots = function () {
        $('.spots').removeClass('desktop');

        $(".spots").owlCarousel({
            items: 2,
            dots: true,
            lazyLoad: true,
            slideSpeed: 800,
            nav: false,
            responsive: {
                0: {
                    items: 1
                },
                460: {
                    items: 2
                },
                600: {
                    items: 3
                },
                920: {
                    items: 4
                }
            }
        });
    }



    $(document).ready(function () {
        var observer = lozad('.lozad');
        observer.observe();

        var placeholder = $("#keywords").data("default-value");
        $("#keywords")
            .focus(function () {
                if ($("#keywords").val() == "") {
                    // position example div and slide down
                    $("#example").css({ visibility: 'hidden', display: 'block' }).position({ my: "left top", at: "left bottom", of: "#keywords", collision: "none" }).css({ visibility: 'visible', display: 'none' }).slideDown(400);
                }
                $("#keywords-default").hide();
            })
            .blur(function () {
                $("#example").hide();
                if ($(this).val() == "") {
                    $("#keywords-default").css('display', 'inline');
                }
            })
            .autocomplete({
                source: function (request, response) {
                    $.post(cobrandPath + "search/suggest",
                        $("#keywords").serialize(),
                        function (data) { response(data) },
                        "json");
                },
                response: function (event, ui) {
                    $("#example").hide();
                },
                select: function (event, ui) {
                    $("#keywords").val(ui.item.value);
                    document.searchForm.submit();
                }
            });

        // hide span on click
        $("#keywords-default").click(function () {
            //$span.hide();
            $("#keywords").focus();
        });

        // setup span initially
        if ($("#keywords").val() != '') {
            $("#keywords-default").hide();
        }

        $("#feat-brokers .next-slide").click(function () {
            $('#feat-brokers .clients')('next.owl.carousel');
        });
        $("#feat-brokers .prev-slide").click(function () {
            $('#feat-brokers .clients')('prev.owl.carousel', [300]);
        });
        $("#feat-franchises .next-slide").click(function () {
            $('#feat-franchises .clients').trigger('next.owl.carousel');
        });
        $("#feat-franchises .prev-slide").click(function () {
            $('#feat-franchises .clients').trigger('prev.owl.carousel', [300]);
        });

        $(".clients").owlCarousel({
            lazyLoad: true,
            loop: true,
            margin: 10,
            dots: true,
            nav: true,
            navText: [],
            responsive: {
                0: {
                    items: 1
                },
                460: {
                    items: 2
                },
                840: {
                    items: 3
                }
            }
        });

        $("#feat-commProp ul").owlCarousel({
            scrollPerPage: true,
            items: 4,
            pagination: true,
            lazyLoad: true,
            slideSpeed: 800,
            itemsCustom: [
                [0, 1],
                [460, 2],
                [600, 3],
                [920, 4]
            ]
        });

    });
})(jQuery);