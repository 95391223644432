(function ($) {

    $(function () {

        //Add close and triangle
        $(".tool-tipBFS").prepend('<span class="fa fa-times tt-close" aria-hidden="true"></span>');


        //Toggle tool tip
        $(".tt-title").on("click", function () {
            $(this).parents(".tt-wrap").toggleClass("hide-tt show-tt");
        });

        //Close tool tip
        $(".tt-close").on("click", function () {
            $(this).parents(".tt-wrap").removeClass("show-tt").addClass("hide-tt");
        });

        //Close tool tip when clicked elsewhere on page
        $(document).on('click', function (event) {
            if ((!$(event.target).parents().is(".tt-wrap")) && ($(".tt-wrap").hasClass("show-tt"))) {
                $(".tt-wrap").removeClass("show-tt").addClass("hide-tt");
            }
        });
    });

    $(document).ready(function () {
        //Toggle tool tip
        $(document).on("click", "#quickEnquire-popup .tt-title", function () {
            var $thisParent = $(this).parents(".tt-wrap");

            $thisParent.toggleClass("hide-tt show-tt");

            if ($thisParent.is("#quickEnquire-popup .show-tt")) {
                $(this).next().prepend('<span class="fa fa-times tt-close" aria-hidden="true"></span>');
            }
        });

        //Close tool tip
        $(document).on("click", "#quickEnquire-popup .tt-close", function () {
            $(this).parents(".tt-wrap").removeClass("show-tt").addClass("hide-tt");
        });

        //Close tool tip when clicked elsewhere on page
        $(document).on("click", function (event) {
            if ((!$(event.target).parents().is(".tt-wrap")) && ($(".tt-wrap").hasClass("show-tt"))) {
                $(".tt-wrap").removeClass("show-tt").addClass("hide-tt");
            }
            if ((!$(event.target).parents().is(".hover-click-tt")) && ($(".hc-tt-cont").css("display", "block"))) {
                $(".hc-tt-cont").hide();
            }
        });

        //Open tooltip on click for mobile
        if ($(window).width() < 1000) {
            $(".close-hc-tt").on("click", function () {
                $(this).parents(".hc-tt-cont").hide();
            });
            $(".hc-tt-event").on("click", function () {
                $(this).parents(".hover-click-tt").find(".hc-tt-cont").show();
            });
        }

        //Open tooltip on hover for desktop
        else {
            var $thisToolTipDT = $(this).find(".hc-tt-cont");
            $(".hover-click-tt").hover(function () {
                $thisToolTipDT.show();
            }, function () {
                $thisToolTipDT.hide();
            });
        }
    });

})(jQuery);